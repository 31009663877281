<template>

    <section id="hotel">
    
        <div class="row pt-4">
        <div class="col-12 col-lg-6 mb-3">
            <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
                Listado de Consultas y Sugerencias en <strong>Hoteleria</strong>
            </h3>
        </div>
        <div class="col-12 col-lg-6 mb-3">
            <div class="row">
            <div class="col-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-lg-end text-secondary">
                <font-awesome-icon icon="filter" class="color-secondary pe-2"/>
                Filtrar
            </div>
            <div class="col-12 col-lg-7 col-xl-7 col-xxl-7">
                <div class="row">
                <div class="col-6">
                    <InputDate 
                    :max="range.date_end"
                    placeHolder="Inicio"
                    @dateEmit="captureDateStart"
                    />
                </div>
                <div class="col-6">
                    <InputDate 
                    :min="range.date_start" 
                    placeHolder="Término"
                    @dateEmit="captureDateEnd"
                    />
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    
        <div class="row">
            <div class="col-12 col-lg-7">
                <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
                    <div class="card-body">
                        <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-between">
                            <h5 class="font-main-bold color-gray">
                            <i class="fa-solid fa-circle-user color-secondary"></i>
                            Consultas y Sugerencias
                            </h5>

                        </div>
                        </div>
    
                        <Loading 
                        v-if="loading"
                        pixeles="150px"
                        />
                        <template v-if="!loading && final_list.length !== 0">
                        <ul class="list-group list-group-flush scroll-custom-550 mt-3" :key="list_key">
                            <li
                            v-for="(ad, i) in final_list"
                            :key="ad.id_solicitud"
                            href="javascript:"
                            class="list-group-item"
                            :class="selectedUserList(ad.id_solicitud)"
                            >
                            <div class="d-flex align-items-top">
                                <a 
                                href="javascript:" 
                                @click="selectSoli(ad, i)"
                                class="flex-grow-1 ms-3 text-decoration-none"
                                > 
                                    <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <img 
                                            class="img-obj img-obj-60 img-obj-circle border" 
                                            :src="handleUserImage(ad.usuario.imagen_perfil)"
                                            />
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <p class="text-secondary">
                                            {{ ad.usuario.primer_nombre | capitalize }}
                                            {{ ad.usuario.apellido_paterno | capitalize }}
                                            {{ ad.usuario.apellido_materno | capitalize }}<br>
                                            <small class="text-black-50">
                                                <i class="fa-solid fa-circle-user color-brown"></i>
                                                {{ ad.usuario.cargo[0].nombre_cargo }}
                                            </small>
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-3 text-end">
                                        <p class="font-size-14 text-secondary">
                                        <i class="fa-solid fa-calendar-days color-secondary"></i>
                                        {{ dateNewFormat(ad.fecha_creacion, 1) }}
                                        </p>
                                    </div>
                                    </div> 
                                </a>
                            </div>
                            </li>
                        </ul>
                        </template>
                        <SinResultados v-if="!loading && final_list.length === 0"/>
    
                        <div class="row mt-3">
                        <div class="col-12">
                            <button class="btn btn-custom-color-white border d-block" :disabled="total_parcial>=total_registros" @click="paginador()"> <i class="fa-solid fa-arrows-rotate color-blue pe-1"></i>Mostrar más ( {{total_parcial+" / "+total_registros}})</button>
                        </div> 
                        </div>
                    </div>
                </div>
    
            </div>
            <div class="col-12 col-lg-5">
            <div class="card border-round-10 card-shadow border-0 mb-3">
                <div class="card-body">
    
                <Loading 
                    v-if="loading"
                    pixeles="150px"
                />
    
                <template v-if="!loading && final_list.length !== 0">             
                    <div class="row mb-3">
                    <div class="col-12 d-flex align-items-center justify-content-between">
                        <h5 class="font-main-bold color-gray mb-0">
                        <i class="fa-solid fa-circle-info color-secondary"></i>
                        Información  
                        </h5>
                    </div>
                    </div>
        
                    <div 
                    v-if="checks.length >= 2"
                    class="alert alert-info text-center">
                    ¿Cambiar el estado de las <strong>{{ checks.length }} solicitudes?</strong>
                    </div>
        
                    <div class="row mb-3">
                        <div>
                            <h4 class="color-secondary text-center">Titulo Solicitud</h4>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="vstack gap-3">
                            <div class="card" v-for="(message, index) in messages" :key="index">
                                <div class="card-body">
                                    {{ message.text }}
                                    <small class="text-muted" style="position: absolute; bottom: 5px; right: 5px;">{{ message.date }}</small>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-3">
                        <div class="input-group">
                            <textarea class="form-control" placeholder="Ingresar mensaje" v-model="message" @focus="clearPlaceholder"></textarea>
                            <button class="btn btn-primary" @click="sendMessage">Enviar</button>
                        </div>
                    </div>

                    
                </template>
    
                <template v-if="!loading && final_list.length === 0">
                    <div class="row">
                    <div class="col-12">
                        <div class="alert alert-info text-center mb-0">
                             
                            Sin resultados para mostrar
                        </div>
                    </div>
                    </div>
                </template>

                </div>
            </div>
            </div>
        </div>
    
        <Status
            v-if="open_modal_status"
            :msg="modal_status_msg"
            :status="modal_status"
            @close="closeStatus"
        />
    </section>
    
    </template>
    
    <script>
    import Status from "../Modales/Status.vue";
    import { mapActions, mapState } from "vuex";
    import "export-json-excel";
    import moment from "moment";
    
    //FF
    import InputDate from "@/components/forms/InputDate.vue"
    import Loading from "@/components/Loading.vue";
    import SinResultados from "@/components/SinResultados.vue";
    
    export default {
        name: "HotelConsult",
        components: { 
        Status,
    
        //FF
        Loading,
        InputDate,
        SinResultados 
        },
        data() {
        return {
            messages: [
                { text: "Hola, ¿cómo estás?", date: "May 8, 2024 12:00 PM" },
                { text: "Estoy bien, gracias.", date: "May 8, 2024 12:01 PM" },
                { text: "¿Y tú?", date: "May 8, 2024 12:02 PM" },
                { text: "Bien también", date: "May 8, 2024 12:03 PM" }
            ],
            data_excel:[],
            fields_excel: [],
            name_excel: '',
            id_empresa: this.$ls.get("user").empresa[0].id_empresa,
            user_id: this.$ls.get("user").id_usuario,
            pagina_actual: 1,
            ids_solicitudes: [""],
            // variable no ocupada se debe elimnar solicitud_selected
            solicitud_selected: {
            nombre: "Todas las solicitudes",
            id_estado_solicitud: "",
            },
            estado_selected: "En espera",
            dow_hover: false,
            open_estado_select_list: false,
            open_indicadores_select_list: false,
            user_default_img: require("@/assets/img/user_default_img.svg"),
            key_calendar_inicio: 0,
            key_calendar_termino: 0,
            calendar_inicio: new Date(),
            calendar_termino: new Date(),
            open_modal_detail: false,
            open_modal_status: false,
            modal_status_msg: false,
            modal_status: true,
            date_inicio: "Inicio",
            date_termino: "Término",
            img_dowload_w: require("@/assets/img/utilities/dowload-w.svg"),
            img_dowload_b: require("@/assets/img/utilities/dowload-b.svg"),
            img_calendar: require("@/assets/img/utilities/calendar.svg"),
            users: [
            { id: 1, name: "Pedro", date: new Date(), state: 1 },
            { id: 2, name: "Juan", date: new Date(), state: 3 },
            ],
            all_check: false,
            admin_list: [],
            checks: [],
            states: [],
            selected_solicitud: { estado_solicitud: { nombre: "" } },
            list_key: 0,
            id_param: this.$route.params.id,
            myli: { data: [], num: 0 },
            show_calendar: false,
            calendar_side: "",
            init_date: "Inicio",
            end_date: "Término",
            max_date: "",
            min_date: "",
            total_registros: 0,
            total_parcial: 0,
    
            //FF
            loading: true,
            filter: { 
            search: ''
            },
            range: {
            date_start: null,
            date_end: null,
            mask_start: null,
            mask_end: null
            },
            sel_estados: {id: 1, label: "En proceso", type: "En proceso" },
            sel_selected_estados: {},
            data_selected_estados: []
        };
        },
        created: function () {
        this.getEstados();
        },
        computed: {
        ...mapState("benefitModule", [
            "benefit_list",
            "benefit_list_edits",
            "data_estados",
            "final_list",
            "paginas_solicitud",
            "termimno_carga"
        ]),
        attributes() {
            return {
            highlight: true,
            color: "#009CDE",
            dates: new Date(),
            };
        },
        },
        watch: {
        all_check(check) {
            if (check) {
            this.checkAllInputs();
            } else {
            this.checks = [];
            this.checks.push({
                solicitud: this.final_list[0].id_solicitud,
                user: this.final_list[0].usuario.id_usuario,
            });
            this.selectSoli(this.final_list[0], 0);
            }
        },
        sel_estados() {
            console.log("sel_estados: ");
            this.pagina_actual = 1;
            this.getPaginas();
        },
        $route: {
            handler: function () {
            this.sel_estados = {id: 1, label: "En proceso", type: "En proceso" };
            console.log("route");
            this.pagina_actual = 1;
            this.id_param = this.$route.params.id;
            this.all_check = true;
            this.all_check = false;
            this.selected_solicitud = null;
            this.show_calendar = false;
            this.calendar_side = "";
            this.init_date = "Inicio";
            this.end_date = "Término";
            this.max_date = "";
            this.min_date = "";
            },
            immediate: true,
        },
        // Esto se agrega para poder controlar el tiempo de renderizado con la lista de beneficios
        termimno_carga() {
            this.loading = false;
            this.total_parcial = this.final_list.length;
            this.getDataExcel();
        },
        //FF
        checks() {
            this.sel_selected_estados.id = this.estado_selected.id_estado_solicitud;
            this.sel_selected_estados.label = this.estado_selected.nombre;
            this.sel_selected_estados.type = this.estado_selected.__typename;
        }, 
        "filter.search"(value) {
            if(value === '') {
            this.getPaginas();
            }
        },
        "range.date_start"() {
            this.getPaginas();
        },
        "range.date_end"() {
            this.getPaginas();    
        },
        },
        mounted() {
        if (this.$route.params.id != undefined && this.$route.params.id != null) {
            //console.log("ingreso por router params");
            //this.getAdminListBenefit();
        }
        else{
            console.log("no ingreso por router params");
        }    
        },
        methods: {
        ...mapActions("benefitModule", [
            "getAdminListSoliAction",
            "changIdSolicitudAction",
            "getEstadosSolicitud",
            "getTipoBeneficio",
            "cambioEstadoSolicitud",
            "getPaginacionSolicitudes",
            "getListSolicitudes",
            "getListSolicitudesAppend"
        ]),
        selectedUserList(id) {
            var results = this.checks.filter((check) => {
            return check.solicitud == id;
            });
            return results.length > 0 && "bg-black-light";
        },
        selectSoli(sol, i) {
            this.selectEstado(sol.estado_solicitud);
            this.$nextTick(() => {
            this.$refs.typeBox[i].focus();
            });
            this.selected_solicitud = sol;
            this.checks = [];
            this.checks.push({
            solicitud: sol.id_solicitud,
            user: sol.usuario.id_usuario,
            });
            //console.log(i)
        },
        // paginador: cambia la variable de las paginas para luego volver a buscar los datos de la siguiente pagina
        paginador(){
            this.pagina_actual = this.pagina_actual +1;
            if (this.pagina_actual > this.paginas_solicitud.length){
            this.pagina_actual = this.paginas_solicitud.length;
            //this.getPaginas();
            }
            else if(this.pagina_actual < 1){
            this.pagina_actual = 1;
            //this.getPaginas();
            }
            else if(this.pagina_actual <= this.paginas_solicitud.length){
            this.getPaginas();
            }
            console.log("revisando la pagina: ", this.pagina_actual);
            
        },
        async getEstados() {
            this.loading = true;
            let data = {}
            await this.getEstadosSolicitud(data).then(resp=>{
            if(resp!=null){
                resp.forEach((element) =>  this.data_selected_estados.push({
                    id: element.id_estado_solicitud,
                    type: element.__typename,
                    label: element.nombre
                }));
                }
                else{
                console.log("getEstados");
                // agregar el control de las fallas.
                }
                this.loading = false;
            }).catch((err) => {
                console.log("err getEstadosSolicitud", err);
                this.loading = false;
            });
        },
        // obtiene las paginas y luego llama al getListSolictudes, pasarle los Id asociados a la pagina actual
        async getPaginas(){
            this.loading = true;
            let data_ = {
            id_empresa: this.id_empresa,
            id_tipo_solicitud: this.$route.params.id,
            historico: this.range.date_start === null || this.range.date_end === null ? "0" : "1",
            fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
            fecha_final: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
            nombre_usuario: this.filter.search,
            id_estado: this.sel_estados.id == '' ? '0': this.sel_estados.id.toString(),
            limite: "40"
            }
            
            console.log("getPaginas: ",data_);
            await this.getPaginacionSolicitudes(data_).then(res=>{
                if (res)
                {
                    res.map((data) => {
                    if (data.pagina == this.pagina_actual) 
                    {
                    this.ids_solicitudes = data.solicitudes;
                    let variables = {
                        id_empresa: this.id_empresa,
                        id_tipo_solicitud: this.$route.params.id,
                        id_solicitud: this.ids_solicitudes
                    }
                    if(this.pagina_actual>1){
                        this.getListSolicitudesAppend(variables).then(resp=>{
                        if(resp!=null && resp){
                            if(resp.length < 1) this.selected_solicitud = null;
                            this.list_key = 1 + this.list_key;
                            }
                            else{
                            console.log("Sin datos para mostrar getListSolicitudesAppend");
                            this.loading = false;
                            // agregar el control de las fallas.
                            }
                            
                        }).catch((err) => {
                        console.log("sin datos getListSolicitudesAppend", err);
                        this.loading = false;
                        });
                        
                    }
                    else if(this.pagina_actual == 1)
                    {
                        this.getListSolicitudes(variables).then(resp=>{
                        if(resp!=null && resp){
                            if(resp.length < 1) this.selected_solicitud = null;
                            if(resp.length>0) this.selectSoli(resp[0], 0);
                            //this.list_view(resp);
                            this.list_key = 1 + this.list_key;
                            }
                            else{
                            console.log("Sin datos para mostrar getListSolicitudes");
                            // agregar el control de las fallas.
                            }
                        }).catch((err) => {
                        console.log("sin datos getListSolicitudes", err);
                        this.loading = false;
                        });
                    }
                    }
                });
                }
            }).catch(err=>{
            this.loading = false;
            console.log("Falla getPaginacionSolicitudes: ", err);
            });
            this.total_registros = this.paginas_solicitud[0].total;
            
        },
        isSelected() {
            if (this.selected_solicitud == null) {
            return [];
            } else {
            return this.selected_solicitud.detalle_solicitud[0].valores_formulario;
            }
        },
        checkAllInputs() {
            this.checks = [];
            // Se ocupa la misma variable final_list para aplicar los check de selección multiple
            for (let i = 0; i < this.final_list.length; i++) {
            this.checks.push({
                solicitud: this.final_list[i].id_solicitud,
                user: this.final_list[i].usuario.id_usuario,
            });
            }
        },
        openSelectIndicadores() {
            this.show_calendar = null; //cerrar calendario
            this.open_estado_select_list = false; // cerrar select de estado
            this.open_indicadores_select_list = !this.open_indicadores_select_list;
        },
        openSelectEstados() {
            this.show_calendar = null;
            this.open_indicadores_select_list = false; //Cerrar select solicitudes
            this.open_estado_select_list = !this.open_estado_select_list;
        },
        selectSolicitud(solicitud) {
            console.log("solicitud filtro por estado", solicitud);
            this.solicitud_selected = solicitud;
            //this.getAdminListBenefit();
            
            this.getPaginas();
            this.open_indicadores_select_list = false;
        },
        selectEstado(state) {
            this.estado_selected = state;
            //console.log("cambio de estado: ", this.estado_selected);
            this.open_estado_select_list = false;
        },
        handleUserImage(img) {
            if (img !== null && img !== "" && img !== undefined) {
            return img;
            } else {
            return this.user_default_img;
            }
        },
        closeStatus() {
            this.open_modal_status = false;
        },
        saveChanges() {
            this.open_modal_status = true;
            this.modal_status_msg = "Cambios guardados con éxito";
        },
        //Formatear fecha
        dateNewFormat(date, type) {
            var day = new Date(date);
            var month = day.getMonth();
    
            if (type == 1) {
            return `${day.getDate()}/${
                month + 1 < 10 ? `0${month + 1}` : month + 1
            }/${day.getFullYear()}`;
            }
            if (type == 2) {
            return `${day.getDate()};${this.meses[month]}`;
            }
        },
        // cambiador de estados de solicitudes
        async changeStatus() {
            if (
            this.checks.length > 0 &&
            this.sel_selected_estados.label != this.estado_selected.nombre) {
            console.log("ENTRAR");
            this.loading = true;
            for (let i = 0; i < this.checks.length; i++) {
                let data = {
                id_empresa: this.id_empresa,
                id_estado: this.sel_selected_estados.id,
                id_solicitud: this.checks[i].solicitud,
                id_usuario: this.checks[i].user,
                id_administrador: this.user_id,
                };
                //console.log("contenidos", data);
                await this.cambioEstadoSolicitud(data).then(resp=>{
                console.log("Data cambio de estado solicitud", resp);
                });
            }
            this.loading = false;
            this.checks = [];
            this.all_check = false;
            }
        },
        list_view(li) {
            var li_ = li;
            var li_adds = this.benefit_list_edits[this.id_param];
            if (li_adds != undefined) {
            if (li_adds.length != this.myli.num) {
                for (let i = 0; i < li_adds.length; i++) {
                var ind = li.findIndex(
                    (my_li) => my_li.id_solicitud == li_adds[i].id_solicitud
                );
                li_.splice(ind, 1, li_adds[i]);
                }
                this.myli.data = li_;
                this.myli.num = li_adds.length;
            }
            }
            this.final_list = li_;
            this.checks = [];
        },
        dataTypeValor(type, valor) {
            if (type == "Ticket" && valor == "true") {
            return `<span class="badge badge-custom badge-custom-green-light font-size-14"><i class="fa-solid fa-check"></i></span>`;
            }
            if (type == "Ticket" && valor == "false") {
            return `<span class="badge badge-custom badge-custom-gray-light font-size-14"><i class="fa-solid fa-ban"></i></span>`;
            }
            if (type == "Archivo") {
            return `<img class="w-100 img-fluid img-thumbnail rounded" src="${valor}"/>`;
            } else {
            return valor;
            }
        },
        dateFormat(date){
            let new_date = new Date(date);
            let month = new_date.getMonth() + 1 < 10
                ? `0${new_date.getMonth() + 1}`
                : new_date.getMonth() + 1;
            let day = new_date.getDate() < 10 ? `0${new_date.getDate()}` : new_date.getDate();
            let year = new_date.getFullYear();
            return day+'-'+month+'-'+year;
        },
    
        //FF
        captureDateStart(date) {
            this.range.date_start = date;
            this.range.mask_start = moment(date).format('YYYY-MM-DD');
        },
        captureDateEnd(date) {
            this.range.date_end = date;
            this.range.mask_end = moment(date).format('YYYY-MM-DD');
        },
        },
        //FF
        filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString().toLowerCase();
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
        }
    };
    </script>
    